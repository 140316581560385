(function () {
    'use strict';

    function HttpReq(url, contentType, input) {
        this.method = input ? 'POST' : 'GET';
        this.data = input ? input : {};
        this.contentType = contentType;
        this.url = url;
    }
    HttpReq.prototype.then = function (resolve, reject) {
        var req = new XMLHttpRequest();
        req.onreadystatechange = function () {
            if (req.readyState == XMLHttpRequest.DONE) {
                if (req.status == 200) { resolve(req.response); }
                else { reject(req.statusText); }
            }
        };
        req.open(this.method, this.url, true);
        req.setRequestHeader('Content-Type', this.contentType);
        req.send(this.data);
    };

    NodeList.prototype.addEventListener = function (type, f,uc) { this.forEach(function (x) { x.addEventListener(type, f, uc); }); };

    function SearchProducts(selector) {
        var searchResult = document.querySelector(selector + ' .searchResult');
        document.querySelectorAll('.search-tgl').addEventListener('click', function () {
            var el = document.querySelector(selector);
            el.classList.toggle('invisible');
            el.classList.toggle('bg-white');
            document.querySelector(selector + ' input').focus();
        });
        document.querySelectorAll('.search-btn').addEventListener('click', function () {
            document.querySelector(selector + ' form').submit();
        });
        document.querySelector(selector + ' input[type="search"]').addEventListener('input', function () {
            document.querySelector('input.search-tgl[type="search"]').value = this.value;
            var val = this.value.trim();
            if (val != "") {
                new HttpReq('/Members/searchlist.aspx?q=' + val, 'text/html; charset=utf-8').then(
                    function (response) {
                        searchResult.innerHTML = response;
                    }, function (status) {
                        console.log({ status: 'خطا', erroeText: status });
                    });
            } else {
                searchResult.innerHTML = "";
            }
        });
    }

    function OnView(el, callback, fullSeen) {
        this.element = el;
        this.callback = callback;
        this.fullSeen = fullSeen;
        this.viewed();
        window.addEventListener('scroll', this.viewed.bind(this));
    }
    OnView.prototype.isview = function () {
        var position = this.element.getBoundingClientRect();
        if (this.fullSeen) {
            if (position.top >= 0 && position.bottom <= window.innerHeight) {
                return true;
            }
        } else if (position.top < window.innerHeight && position.bottom >= 0) {
            return true;
        }
        return false;
    };
    OnView.prototype.viewed = function () {
        if (this.isview()) {
            this.callback(this.element);
            window.removeEventListener("scroll", this.viewed , true);
        }
    };

    function Lazy(name, start, call) {
        this.call = call;
        document.querySelectorAll('.' + name + '-lazy').forEach(function (el) {
            if (start) {
                this.run(el);
            } else {
                new OnView(el, this.run.bind(this), false);
            }
        }.bind(this));
    }
    Lazy.prototype.run = function (el) {
        if (el.dataset.bg) {
            el.style.backgroundImage = "url('" + el.dataset.bg + "')";
            delete el.dataset.bg;
        }
        if (el.dataset.src && el.dataset.src != el.src) {
            var newImg = new Image();
            newImg.src = el.dataset.src;
            if (this.call) { this.call(newImg,el);}
            newImg.addEventListener('load', function () {
                el.src = this.src;
                delete el.dataset.src;
            }, true);
            newImg.addEventListener('error', function () {
                el.dataset.src = el.src;
            }, true); 
        }
        if (el.dataset.bg) {
            el.style.backgroundImage = "url('" + el.dataset.bg + "')";
        }
    };

    //const modalTriggers = document.querySelectorAll('.popup-trigger')
    //const modalCloseTrigger = document.querySelector('.popup-modal__close')
    //const bodyBlackout = document.querySelector('.body-blackout')

    //modalTriggers.forEach(trigger => {
    //    trigger.addEventListener('click', () => {
    //        const { popupTrigger } = trigger.dataset
    //        const popupModal = document.querySelector(`[data-popup-modal="${popupTrigger}"]`)
    //        popupModal.classList.add('is--visible')
    //        bodyBlackout.classList.add('is-blacked-out')

    //        popupModal.querySelector('.popup-modal__close').addEventListener('click', () => {
    //            popupModal.classList.remove('is--visible')
    //            bodyBlackout.classList.remove('is-blacked-out')
    //        })
    //    })
    //})

    function Modal() {
        var triggers = document.querySelectorAll('[data-modal]');
        //console.log(triggers);
        triggers.forEach(function(trigger){
            var modal = document.querySelector('#' + trigger.dataset.modal);
            console.log(modal);
            trigger.addEventListener('click', function () {
                console.log(modal.className);
                modal.classList.remove('invisible');
            });
        });
    }

    //function Menu(selector) {
    //    this.nav = document.querySelector(selector);
    //    this.toggle_fnc = function () {
    //        if (!this.nav.hasAttribute('created')) {
    //            //creatingMenu(selector);
    //            this.nav.addAttributes('created');
    //        }
    //        nav.classList.toggle('open');
    //    };



    ////    this.config = config;
    ////    this.config.selector.style.overflow = 'hidden';
    ////    this.wrapper = config.selector.querySelector('div');

    ////    this.wrapper.setAttributes({ style: { position: 'relative', left: '0px', transition: 'left ' + this.config.duration + 'ms ease-out 0s' } });
    ////    this.wrapper.dataset.select = 0;
    ////    if (config.control) {
    ////        config.selector.querySelector('.next').addEventListener('click', this.next.bind(this));
    ////        config.selector.querySelector('.prev').addEventListener('click', this.prev.bind(this));
    ////    }
    ////    window.addEventListener('resize', this.init.bind(this), false);
    ////    this.items = Array.prototype.slice.call(this.wrapper.children);
    ////    this.init();
    //}

    var responsiveMultiMenu = function (selector) {
        var nav = document.querySelector(selector);
        var createMenu = false;
        var toggle = function () {
            if (createMenu === false) {
                creatingMenu(selector);
                createMenu = true;
            }
            nav.classList.toggle('open');
        };

        document.querySelector(selector + '>div').addEventListener("click", toggle);
        document.querySelector(selector + 'Toggle').addEventListener("click", toggle);



        var creatingMenu = function (selector) {
            document.querySelectorAll(selector + '>ul ul').forEach(function (ul) {
                ul.classList.add('rmm-submenu');
                ul.insertAdjacentHTML('afterbegin', '<li class="rmm-back"><a href="#">بازگشت به قبل</a></li>');
                var back = ul.getElementsByClassName('rmm-back')[0].getElementsByTagName('a')[0];
                back.addEventListener("click", function () {
                    var submenu = this.parentNode.parentNode;
                    submenu.previousElementSibling.classList.remove("rmm-subover-header");
                    submenu.classList.remove("rmm-subview");
                    Array.from(submenu.parentNode.parentNode.children).forEach(function (uncle) {
                        uncle.classList.remove("rmm-subover-hidden");
                    });
                });
                var link = ul.previousElementSibling;
                link.classList.add("rmm-dropdown");
                link.getElementsByTagName('span')[0].addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var submenu = this.parentNode.nextElementSibling;
                    submenu.classList.add("rmm-subview");
                    Array.from(submenu.parentNode.parentNode.children).forEach(function (uncle) {
                        uncle.classList.remove("rmm-subover-visible");
                        uncle.classList.add("rmm-subover-hidden");
                    });
                    submenu.parentNode.classList.remove("rmm-subover-hidden");
                    submenu.parentNode.classList.add("rmm-subover-visible");
                    this.parentNode.classList.add("rmm-subover-header");
                });
            });
        };
    };

    window.addEventListener("DOMContentLoaded", function () {
        setTimeout(function () { document.body.classList.remove('preload'); }, 350);
        responsiveMultiMenu('#rmm');
        new Lazy('b', true);
        new Lazy('bp', false);
        new Modal();
        SearchProducts('#searchPane');
    }, false);

}());
